<template>
  <div class="container">
    <v-card class="my-card">
      <v-card-text>
        <v-form ref="form" >
          <v-row no-gutters>
            <v-col cols="12" class="form-center">
              <strong>Title :</strong>
              <v-text-field 
                v-model="message.title" 
                label="Entrez title" 
                solo
                class="my-text-field"
              ></v-text-field>

              <strong>Description :</strong>
              <v-textarea
                v-model="message.body" 
                label="Entrez description" 
                solo
                class="my-textarea"
              ></v-textarea>
            
              <v-btn 
                @click="addmessage" 
                color="primary"
                class="my-btn"
              >
                Envoyer 
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        
        <!-- Success/Error Dialog -->
        <v-dialog v-model="dialog" max-width="400">
          <v-card>
            <v-card-title>{{ dialogTitle }}</v-card-title>
            <v-card-text>{{ dialogMessage }}</v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="dialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import axios from 'axios';
import Constant from "@/utils/constants";

export default {
  data() {
    return {
      library: {
            id: null,
        },

      message: {
        title: '',
        body: '',
        libraryId: null,
      },
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
    };
  },
  
  created() {
      const storedOwnerId = localStorage.getItem('ownerId');
    if (storedOwnerId) {
      this.ownerId = parseInt(storedOwnerId, 10);
      this.fetchLibraryDetails(); // Récupérer les détails de la bibliothèque au chargement du composant
    } else {
    }

    },
  methods: {
    async fetchLibraryDetails() {
      try {
        const response = await axios.get(Constant.QORANI + `library/library/getByOwnerId/${this.ownerId}`);
        this.library = response.data;
        // console.log('Library ID:', this.library.id);  // Log the library ID
      } catch (error) {
        // console.error('Failed to fetch library details:', error);
      }
    },
    /************************************ ajouter *********************************** */
    addmessage() {
      const notification = {
        title: this.message.title,
        body: this.message.body,
        libraryId: this.library.id 
      };

      //  console.log(notification);
      
      axios
        .post(Constant.QORANI + `user/sendNotification`, notification).then(response => {
          // console.log('notification ajoutée :', response.data);
          // console.log('title :', notification.title);
          // console.log('body  :', notification.body);
          // console.log('libraryId  :', notification.libraryId);

          this.showDialog('Succès', 'Notification ajoutée avec succès!');
          this.$refs.form.reset();
          this.message.libraryId = null;
        })
        .catch(error => {
          this.showDialog('Erreur', 'Impossible d\'ajouter une notification. Veuillez réessayer.');
        });
  },

  showDialog(title, message) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialog = true;
  }
}
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 80vw;
  padding: 30px;
  background-color: #f5f5f5;
}

.my-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.my-text-field {
  margin: 15px 0;
  width: 600px; 
}

.my-textarea {
  margin: 15px 0;
  width: 600px; 
}

/* Center both fields and the button */
.form-center {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.my-btn {
  margin: 20px auto;
  display: block;
}

</style>
